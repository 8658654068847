import {TransactionItem} from "../types";
import React from "react";
import {useSettings} from "../../../SettingsContext";
import {useFormatters} from "../../formatters";
//@ts-ignore
import classNames from "classnames";

interface Props {
  item: TransactionItem
}
const OrderItemRow = (props: Props) => {
  const {settings} = useSettings();
  const formatters = useFormatters(settings)
  
  const {item} = props;
  
  const classes = classNames({
    "order-item": true, 
    'receipe-item': item.orderItemType === 'RecipeItem',
    'article': item.orderItemType === 'Article',
    'vat-item': item.orderItemType === 'VAT'
  });
  
  const hideAmount = item.orderItemType === 'RecipeItem' && item.amount === 0;
  
  return (
    <li className={classes}>
      <span className={"description"}>{item.description} ({item.code})</span>
      <span className={"quantity"}>{item.quantity} {item.unit}</span>
      <span className={"price"}>
      {!hideAmount &&(
      <>{formatters.currency.format(item.amount)}</>
      )}
      </span>
    </li>
  )
}
export default OrderItemRow;