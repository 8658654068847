import React, {ChangeEvent, KeyboardEvent, useEffect} from "react";
//@ts-ignore
import {useTranslation} from "react-i18next";
import {useTraceUpdate} from "../../Dashboard/hooks/useTraceUpdate";
import {useDebounce} from "use-debounce";


export type OptionValue = string|number|null;
export interface Option {
  value: OptionValue,
  label: string
}

interface Props {
  label?: string,
  className?: string,
  value?:OptionValue,
  options?:Option[],
  editable?:boolean,
  emptyText?:string,
  onChange?:(value:OptionValue | null)=>void
  queryOptions?: (query:string)=>Promise<void|Option[]>;
  showClear?:boolean,
  description?:string
  debug?:boolean
}


const Picker = (props:Props)=>{
  const {t} = useTranslation();
  if(props.debug)
    console.log('Picker.value',props.value);
  const [selectedOption, setSelectedOption] = React.useState<Option|null>(props.options?.find(t=>t.value == props.value) ?? null);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [filteredOptions, setFilteredOptions] = React.useState<Option[]>(props.options ?? []);
  const [debouncedQueryOptions] = useDebounce(props.queryOptions, 2000);
  
  if(props.debug){
    console.log('Picker.queryOptions', props.queryOptions);
  }
  React.useEffect(()=>{
    if(props.queryOptions) {
      fetchQueryOptions('').then((options)=>{
        setSelectedOption(options?.find(t=>t.value == props.value) ?? null);
        //updateList('')
      });
    } else {
      setSelectedOption(props.options?.find(t=>t.value == props.value) ?? null);
      updateList('');
    }
  },[props.options, debouncedQueryOptions]);
  
  useEffect(()=>{
    setSelectedOption(props.options?.find(t=>t.value == props.value) ?? null);
  },[props.value])
  
  
  const fetchQueryOptions = (query:string) => {
    if(props.queryOptions)
      return props.queryOptions(query).then((options)=>{
        setFilteredOptions(options ?? []);
        return options;
      });
    return Promise.resolve();
  };
    
  const onInputChanged = (e:ChangeEvent<HTMLInputElement>) => {
    if(props.queryOptions){
      fetchQueryOptions(e.target.value)
        .then(()=>{
          setQuery(e.target.value);
          updateList(e.target.value);
        })
      
    } else {
    setQuery(e.target.value);
    updateList(e.target.value);
    }
  }

  const updateList  = (filter:string) =>{
    const filtered = props.options?.filter(t=>t.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0) ?? [];
    setFilteredOptions(filtered);
  }

  const onItemClicked = (option:Option | null) => {
    setSelectedOption(option);
    props.onChange && props.onChange(option?.value ?? null);
    setEditing(false);
    setQuery('');
    updateList('');
  };

  const closeDialog = () => {
    setEditing(false);
    setQuery('');
    updateList('');
  };
  
  const onKeyDown = (e:KeyboardEvent<HTMLDivElement>) => {
    if(e.key == "Escape") {
      closeDialog();
    }
  };
  
  return (
    <>
      <div className={`input-group fancy-picker fancy-dropdown ${(props.editable??true) ? "editable" : ""} ${(editing??true) ? "open" : ""}`} onKeyDown={onKeyDown}>
        {props.debug && (
          <pre>{JSON.stringify({selectedOption, value: props.value, editing, query, filteredOptions}, null, 2)}</pre>
        )}
        {props.label && (
          <label className={"form-label input-group-text"}>{props.label}</label>
        )}
        <div className={"fancy-picker-select"}>
          {selectedOption && (
          <span className={"selected-item"}>
            <span className={"selected-item-label"}>{selectedOption?.label}</span>
            {selectedOption?.value && (
            <span className={"selected-item-value"}> ({selectedOption?.value})</span>
            )}
          </span>
          )}

          {!selectedOption && (
            <span className={"selected-item empty"}>
                {props.emptyText ?? t('components.picker.empty')}
            </span>
          )}

          {props.showClear && selectedOption && (
            <button className={"btn btn-clear"} onClick={(e)=>{
              e.preventDefault();
              onItemClicked(null);
            }}>
              <span className={"sr-only"}>{t('components.picker.clear')}</span>
            </button>
          )}
          
          {(props.editable?? true) && (
            <button className={"btn btn-dropdown"} onClick={(e)=>{
                e.preventDefault();
                setEditing(true);}
            }>
              <span className={"sr-only"}>{t('components.picker.edit')}</span>
            </button>
          )}

          
        
        
          {editing && ( 
            <div className={"fancy-picker-dropdown"}>
              <div className={"input-group"}>
                <label className={"form-label input-group-text"}>{t('components.picker.search')}</label>
                <input className={"form-control"} type={"search"} value={query} onChange={onInputChanged}/>
                <button className={"btn btn-close"} onClick={closeDialog}>
                  <span className={"sr-only"}>{t('components.picker.search')}</span>
                </button>
              </div>
              <ul>
                {filteredOptions.map(t => (
                  <li key={t.value} onClick={()=>onItemClicked(t)} className={(t.value == selectedOption?.value)?"selected":""}>
                    <span className={"option-label"}>{t.label}</span>
                    {t.value && (
                    <span className={"option-value"}>{t.value}</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {props.description && (
          <p className={"form-info"}>{props.description}</p>
        )}
      </div>
      {editing && (<div className="dialog-backdrop" onClick={closeDialog}></div>)}
    </>
  );
}

export default Picker;