import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDebounce} from "use-debounce";
import Select from "react-select";
import Picker from "../settings/Picker";
import {ProfitCenter} from "../../../types";
import Loading from "../../Loading";
import FancyCheckbox from "../FancyCheckbox";
import ColorPicker from "./ColorPicker";
import ImageUpload from "../ImageUpload";
import LineNumberedText from "./LineNumberedText";
import {RGBColor} from "react-color";
import rgbHex from "rgb-hex";

interface State {
  shopId: string | null;
  note: string;
  orderType: string | null;
  multiline: boolean;
  color:RGBColor;
  backgroundColor:RGBColor;
  textColor:RGBColor;
  textSize:number;
  width:number;
  image: string|null;
  imageSizePercent: number;
  useDeliverySpots: boolean;
}

const GenerateQR = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [shops, setShops] = useState<ProfitCenter[]>([]);
  const [state, setState] = useState<State>({
    note: '',
    orderType: null,
    shopId: null,
    multiline: false,
    useDeliverySpots: false,
    color: { r: 0, g: 96, b: 133, a: 1 },
    backgroundColor:{ r: 255, g: 255, b: 255, a: 1 },
    textColor:{ r: 0, g: 0, b: 0, a: 1 },
    textSize:50,
    width:512,
    image:null,
    imageSizePercent: 20
  });
  const [debouncedState] = useDebounce(state, 500);

  const orderTypeOptions = [
    {value: 'TakeAway', label: t(`pick-n-pay.settings.orderTypes.TakeAway`)},
    {value: 'DineInPlace', label: t(`pick-n-pay.settings.orderTypes.DineInPlace`)}
  ];

  
  
  useEffect(() => {
    setLoading(true);
    fetch('api/pick-n-pay/shops')
      .then(res => res.json())
      .then(data => {
        const shops = data.map((shop: any) => {
          return {
            value: shop.id,
            label: shop.description
          };
        });
        setShops(shops);
        setLoading(false);
      })
  }, [])
  
  useEffect(()=>{
    setPreviewUrl(generate());
    setDownloadUrl(generate(false));
  },[debouncedState])

  const generate = (preview:boolean=true) => {
    const toHex = (c: RGBColor) => {
      let result =  rgbHex(c.r, c.g, c.b, c.a);
      return '#'+result;
    }
    
    const queryString = new URLSearchParams();
    if(preview){
      queryString.set('preview', 'true');
    }
    
    if(state.shopId)
      queryString.set('shopId', state.shopId.toString());
    
    if(state.note)
      queryString.set('note', state.note);
    
    if(state.orderType)
      queryString.set('orderType', state.orderType.toString());
    
    if(state.multiline)
      queryString.set('multiline', state.multiline.toString());
    
    if(state.useDeliverySpots)
      queryString.set('deliverySpots', state.useDeliverySpots.toString());
    
    queryString.set('color', toHex(state.color));
    queryString.set('backgroundColor', toHex(state.backgroundColor));
    queryString.set('textColor', toHex(state.textColor));
    queryString.set('textSize', state.textSize?.toString() ?? '');
    queryString.set('image', state.image ?? '');
    queryString.set('width', state.width.toString() ?? '');
    queryString.set('imageSizePercent', state.imageSizePercent.toString() ?? '');
        
    
    return `api/pick-n-pay/generate-qr?${queryString.toString()}`;
  }

  const [previewUrl, setPreviewUrl] = useState<string>(generate());
  const [downloadUrl, setDownloadUrl] = useState<string>('');
  
  if (loading)
    return (<div className={"pick-n-pay generate-qr"}><Loading visible={true}/></div>);

  const showMultiDownload = ()=>{
    if(state.multiline)
      return true;
    if(state.useDeliverySpots)
      return true;
    return false;
  }
  
  return (
    <div className={"pick-n-pay generate-qr"}>
      <div className={"filter-container"}>
        <h1>{t('pick-n-pay.generate-qr.header')}</h1>
        <p>
          {t('pick-n-pay.generate-qr.description')}
        </p>
      </div>
      <div className={"generator-container"}>
        <form className={"generator-form"} onSubmit={()=>{generate()}}>
          <div className={"form-group"}>
            <Picker
              label={t('pick-n-pay.generate-qr.location.label')}
              showClear={true}
              options={shops}
              description={t('pick-n-pay.generate-qr.location.description')}
              onChange={(e: any) => {
                setState({...state, shopId: e});
              }}
            />

            {state.shopId && (
              <>
                <div className={"input-group"}>
                  <label className={"form-label input-group-text"} htmlFor={"orderTypes"}>{t('pick-n-pay.generate-qr.orderType.label')}</label>
                  <Select className={"select"} name={"orderTypes"} onChange={(newValue: any) => {
                    setState({...state, orderType: newValue?.value ?? null});
                  }} defaultValue={state.orderType} options={orderTypeOptions} isMulti={false} isClearable={true}/>
                  <p className={"form-info"}>
                    {t('pick-n-pay.generate-qr.orderType.description')}
                  </p>
                </div>

              </>

            )}
          </div>
          <div className={"form-group"}>
          {state.shopId && (
              <>
                {state.orderType && (
                <div className={"input-group delivery-spot-select"}>
                  <label>{t('pick-n-pay.generate-qr.useDeliverySpots.label')}</label>
                  <FancyCheckbox checked={state?.useDeliverySpots ?? false} disabled={state?.multiline} name={"useDeliverySpots"} label={t('pick-n-pay.generate-qr.useDeliverySpots.label2')}
                                 onChange={(e) => {
                                   setState({...state, useDeliverySpots: e.target.checked, multiline: false})
                                 }}/>
                  <p className={"form-info"}>{t('pick-n-pay.generate-qr.useDeliverySpots.description')}</p>
                </div>
                )}
              </>

            )}
            <div className={"input-group"}>
              <label className={"form-label input-group-text"} htmlFor={"note"}>{t('pick-n-pay.generate-qr.note.label')}   </label>

              {!state.multiline && (
                <input type={"text"} id={"note"} maxLength={128} value={state.note} onChange={(e) => setState({...state, note: e.target.value})}/>
              )}
              {state.multiline && (
                <LineNumberedText id={"note"} value={state.note} onChange={(e) => setState({...state, note: e.target.value})}/>
              )}

              <p className={"form-info"}>
                {t('pick-n-pay.generate-qr.note.description')}
              </p>
            </div>


            <div className={"input-group"}>
              <label>{t('pick-n-pay.generate-qr.multiLine.label')}</label>
              <FancyCheckbox checked={state?.multiline ?? false} disabled={state?.useDeliverySpots} name={"multiline"} label={t('pick-n-pay.generate-qr.multiLine.label2')}
                             onChange={(e) => {
                               setState({...state, multiline: e.target.checked})
                             }}/>
              <p className={"form-info"}>{t('pick-n-pay.generate-qr.multiLine.description')}</p>
            </div>
          </div>
          <div className={"form-group"}>

            <div className={"input-group"}>
              <span className={"label"}>{t('pick-n-pay.generate-qr.icon.label')}</span>
              <ImageUpload currentImage={state.image}
                           onImageUploaded={(imageId) => {
                             setState({...state, image: imageId});
                           }}/>
              <p className={"form-info"}>{t('pick-n-pay.generate-qr.icon.description')}</p>
            </div>
          </div>
        </form>
        <div className={`qr-container form-group ${state.multiline ? 'download' : ''}`}>
          {previewUrl && (
            <>
              {imageLoading && <Loading visible={true}/>}
              <img onLoad={()=>setImageLoading(false)} className={"qr-code"} src={previewUrl} alt={t('pick-n-pay.generate-qr.generatedImage.description')}/>
            </>
          )}
          <div className={"colors-section"}>
            <div className={"input-group"}>
              <ColorPicker onChange={(color) => {
                setState({...state, color: color});
              }} color={state.color} label={t('pick-n-pay.generate-qr.color.label')}/>
              <p className={"form-info"}>{t('pick-n-pay.generate-qr.color.description')}</p>
            </div>
            <div className={"input-group"}>
              <ColorPicker onChange={(color) => {
                setState({...state, backgroundColor: color});
              }} color={state.backgroundColor} label={t('pick-n-pay.generate-qr.backgroundColor.label')}/>
              <p className={"form-info"}>{t('pick-n-pay.generate-qr.backgroundColor.description')}</p>
            </div>

            <div className={"input-group"}>
              <ColorPicker onChange={(color) => {
                setState({...state, textColor: color});
              }} color={state.textColor} label={t('pick-n-pay.generate-qr.textColor.label')}/>
              <p className={"form-info"}>{t('pick-n-pay.generate-qr.textColor.description')}</p>
            </div>
          </div>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"} htmlFor={"imageSize"}>
              {t('pick-n-pay.generate-qr.imageSize.label')}
            </label>
            {state.width} px
            <input min={256} step={32} max={1024} type={"range"} id={"imageSize"} value={state.width}
                   onChange={(e) => setState({...state, width: Number(e.target.value)})}/>
            <p className={"form-info"}>{t('pick-n-pay.generate-qr.imageSize.description')}</p>
          </div>
          <div className={"input-group"}>
              <label className={"form-label input-group-text"} htmlFor={"textSize"}>{t('pick-n-pay.generate-qr.textSize.label')}</label>
              {state.textSize}
              <input min={10} max={100} type={"range"} id={"textSize"} value={state.textSize}
                    onChange={(e) => setState({...state, textSize: Number(e.target.value)})}/>
              <p className={"form-info"}>
                {t('pick-n-pay.generate-qr.textSize.description')}
              </p>
          </div>

          {state.image && (
            <div className={"input-group"}>
              <label className={"form-label input-group-text"} htmlFor={"imageSizePercent"}>{t('pick-n-pay.generate-qr.iconSize.label')}</label>
              {state.imageSizePercent} %
              <input min={20} step={1} max={50} type={"range"} id={"imageSizePercent"} value={state.imageSizePercent}
                    onChange={(e) => setState({...state, imageSizePercent: Number(e.target.value)})}/>
              <p className={"form-info"}>{t('pick-n-pay.generate-qr.iconSize.description')}</p>
            </div>
          )}

          {!showMultiDownload() && downloadUrl && (
            <a className={"btn btn-download"} href={downloadUrl} download title={t('pick-n-pay.generate-qr.download.title')}>
              {t('pick-n-pay.generate-qr.download.text')}
            </a>
          )}
          
          {showMultiDownload() && downloadUrl && (
            <>
              <p>{t('pick-n-pay.generate-qr.downloadMulti.description')}</p>
              <a className={"btn btn-download"} href={downloadUrl} download title={t('pick-n-pay.generate-qr.downloadMulti.title')}>
                {t('pick-n-pay.generate-qr.downloadMulti.text')}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default GenerateQR;