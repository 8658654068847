//@ts-ignore
import {FormControl, FormText, InputGroup} from "react-bootstrap";
//@ts-ignore
import {InputGroupText} from "reactstrap";
//@ts-ignore
import React, {ChangeEvent, useState} from "react";
//@ts-ignore
import moment from "moment/moment";
//@ts-ignore
import {useTranslation} from "react-i18next";
//@ts-ignore
import {CompareRange} from "../types";

interface Props {
  onPeriodChanged: (from:string, to:string) => void;
  showWeekNumber: boolean;
  showRange: boolean;
  onShowRangeChanged: (showRange:boolean) => void;
  initialFrom: string|null;
  initialTo: string|null;
  onCompareToChanged?: (compareTo:string|null) => void;
  onCompareRangeChanged?: (compareRange:CompareRange|null) => void;
  onCompareActiveChanged?: (compareActive:boolean) => void;
}

const PeriodSelector = (props:Props) => {
  const {onPeriodChanged, showWeekNumber, showRange, onShowRangeChanged, initialFrom, initialTo, onCompareToChanged, onCompareRangeChanged, onCompareActiveChanged} = props;
  const [state, setState] = useState({ 
    periodFrom: moment(initialFrom).format("YYYY-MM-DD"), 
    periodTo: initialTo ?? moment(initialFrom).add(5,'days').format("YYYY-MM-DD"),
    compareTo: '',
    compareRangeFrom: '',
    compareRangeTo: '',
  });
  const {t} = useTranslation();
  
  const [rangeActive, setRangeActive] = React.useState(showRange);
  const [compareActive, setCompareActive] = React.useState(false);
  
  const onDateChanged = (e:ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    
    let from = state.periodFrom;
    let to = state.periodTo;
    if (e.target.name === 'period')
      from = newValue;
    else
      to = newValue;

    onPeriodChanged(from, to);
    setState({...state, periodFrom:from, periodTo: to});
  };
  
  const compareDateChanged = (e:ChangeEvent<HTMLInputElement>)=>{
    const newValue = e.target.value;
    setState({...state, compareTo: newValue});
    onCompareToChanged && onCompareToChanged(newValue);
  }
  
  const compareRangeDateChanged = (e:ChangeEvent<HTMLInputElement>)=>{
    const newValue = e.target.value;
    const name = e.target.name;
    let range:CompareRange = {
      compareRangeFrom: state.compareRangeFrom,
      compareRangeTo: state.compareRangeTo
    };
    range[e.target.name] = newValue;
    
    setState({...state, [name]:newValue});
    onCompareRangeChanged && onCompareRangeChanged(range);
  }

  const periodWeekNumber = moment(state.periodFrom).isoWeek();
  const period2WeekNumber = moment(state.periodTo).isoWeek();
  
  const rangeActiveChanged = (e:ChangeEvent<HTMLInputElement>) => {
    setRangeActive(e.target.checked)
    if (onShowRangeChanged)
      onShowRangeChanged(e.target.checked);
  };
  
  const compareActiveChanged = (e:ChangeEvent<HTMLInputElement>)=>{
    if(e.target.checked){
      setCompareActive(true);
      if(onCompareToChanged){
        onCompareToChanged(state.compareTo);
      }
      
      if(onCompareRangeChanged){
        onCompareRangeChanged({
          compareRangeFrom: state.compareRangeFrom,
          compareRangeTo: state.compareRangeTo,
        })
      }
      
    } else {
      setCompareActive(false);
      setState({...state, compareTo: ''});
      
      onCompareToChanged && onCompareToChanged(null);
      onCompareRangeChanged && onCompareRangeChanged(null);
    }
    
    onCompareActiveChanged && onCompareActiveChanged(e.target.checked);
  };

  return (

      <div className={"row period"}>
        <InputGroup className={"period-select"}>
          <label className={"filter-label"}>
            {rangeActive && (t("period-selector.from"))}
            {t("period-selector.period")}
          </label>
          <FormControl name={"period"} className='input-date' type={"date"} value={state.periodFrom} max={state.periodTo} onChange={onDateChanged}/>
          {showWeekNumber && (
              <FormText color="muted" className={"mx-3"}>
                ({t('period-selector.week')} {periodWeekNumber})
              </FormText>
          )}
        </InputGroup>

        <div className={"checkbox-wrapper"}>
          <input id={"rangeActive"} type={"checkbox"} name={"rangeActive"} onChange={rangeActiveChanged} />
            <label className="toggle" htmlFor={"rangeActive"}>
              <span className="toggler">
                <svg width="10px" height="10px" viewBox="0 0 10 10">
                  <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                </svg>
              </span>
              
          </label>
          <label htmlFor={"rangeActive"}>
            <span>{t('period-selector.from-to')}</span>
          </label>
        </div>
        
        {rangeActive && (
            <InputGroup className={"col-1"} style={{maxWidth: 400}}>
              <InputGroupText>{t('period-selector.to')} {t('period-selector.period')}</InputGroupText>
              <FormControl name={"period2"} className='input-date' type={"date"} min={state.periodFrom} value={state.periodTo} onChange={onDateChanged}/>
              {showWeekNumber && (
                  <FormText color="muted" className={"mx-3"}>
                    ({t('period-selector.week')} {period2WeekNumber})
                  </FormText>
              )}
            </InputGroup>
        )}

        <div className={"checkbox-wrapper"}>
          <input id={"compareActive"} type={"checkbox"} name={"compareActive"} onChange={compareActiveChanged} />
            <label className="toggle" htmlFor={"compareActive"}>
              <span className="toggler">
                <svg width="10px" height="10px" viewBox="0 0 10 10">
                  <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                </svg>
              </span>
              
          </label>
          <label htmlFor={"compareActive"}>
            <span>{t('period-selector.compare')}</span>
          </label>
        </div>
        
        {compareActive && (
          <>
            {!rangeActive && (
              <InputGroup className={"col-1"} style={{maxWidth: 400}}>
                <InputGroupText>{t('period-selector.compare-to')}</InputGroupText>
                <FormControl name={"period3"} className='input-date' type={"date"} value={state.compareTo} onChange={compareDateChanged}/>
              </InputGroup>
            )}
            
          {rangeActive && (
            <div className={"row g-3"}>
              <div className={"col-auto"}>
                <InputGroup >
                  <InputGroupText>{t('period-selector.compare-from')}</InputGroupText>
                  <FormControl name={"compareRangeFrom"} className='input-date' type={"date"} value={state.compareRangeFrom} max={state.compareRangeTo} onChange={compareRangeDateChanged}/>
                </InputGroup>
              </div>
              
              <div className={"col-auto"}>
              <InputGroup className={"col-1"}>
                <InputGroupText>{t('period-selector.compare-to')}</InputGroupText>
                <FormControl name={"compareRangeTo"} className='input-date' type={"date"} value={state.compareRangeTo} min={state.compareRangeFrom} onChange={compareRangeDateChanged}/>
                {showWeekNumber && (
                  <FormText color="muted" className={"mx-3"}>
                    ({t('period-selector.week')} {period2WeekNumber})
                  </FormText>
                )}
              </InputGroup>
              </div>
            </div>
            )}
          </>
        )}
      </div>

  )
}

PeriodSelector.defaultProps = {
  onPeriodChanged: null,
  showWeekNumber: false,
  period: null,
  showRange: false
};

export default PeriodSelector;