import React, {useEffect, useState} from "react";
//@ts-ignore
import {Link, useNavigate} from "react-router-dom";
//@ts-ignore
import {PaginatedResult} from "../../../types";
import {OrderSearchFilter, TransactionHeader} from "../types";
import OrderRow from "../orders/OrderRow";
//@ts-ignore
import {useTranslation} from "react-i18next";
import Select from "react-select";
import ButtonConfirm from "../settings/ButtonConfirm";
import {t} from "i18next";
import LabelValue from "../LabelValue";

interface Props {
  customer: any;
  showOrders?: boolean;
  showActions?: boolean;
  showAccountStatus?:boolean;
  linkToCustomer?: boolean;
  onClose?: () => void;
}

const CustomerDetails = (props: Props) => {
  const {t} = useTranslation();
  const statuses = [
    {value: 'Added', label: t('pick-n-pay.orders.status.added')},
    {value: 'Started', label: t('pick-n-pay.orders.status.started')},
    {value: 'Delivered', label: t('pick-n-pay.orders.status.delivered')},
    {value: 'Finished', label: t('pick-n-pay.orders.status.finished')},
    {value: 'ArivedFromClient', label: t('pick-n-pay.orders.status.arrivedfromClient')},
    {value: 'CalculatedPrice', label: t('pick-n-pay.orders.status.calculatedPrice')},
    {value: 'Cancelled', label: t('pick-n-pay.orders.status.cancelled')},
    {value: 'Rollbacked', label: t('pick-n-pay.orders.status.rollbacked')},
    {value: 'RegistrationFailed', label: t('pick-n-pay.orders.status.registrationfailed')},
    {value: 'AuthorizeFailed', label: t('pick-n-pay.orders.status.authorizefailed')},
    {value: 'PlaceOrderFailed', label: t('pick-n-pay.orders.status.placeorderfailed')},
    {value: 'CaptureFailed', label: t('pick-n-pay.orders.status.capturefailed')},
    {value: 'QueryTransactionFailed', label: t('pick-n-pay.orders.status.querytransactionfailed')},
    {value: 'CommitPaymentFailed', label: t('pick-n-pay.orders.status.commitpaymentfailed')},
    {value: 'DeleteOrderOK', label: t('pick-n-pay.orders.status.deleteorderok')},
    {value: 'DeleteOrderFailed', label: t('pick-n-pay.orders.status.deleteorderfailed')},
    {value: 'Unknown', label: t('pick-n-pay.orders.status.unknown')}
  ];

  const getDefaultStatusFilter = () => {
    return [
      statuses.find((status) => status.value === 'Added')!,
      statuses.find((status) => status.value === 'Started')!,
      statuses.find((status) => status.value === 'Delivered')!,
      statuses.find((status) => status.value === 'Finished')!,
    ];
  }
  
  const {showOrders, showActions} = props;
  const [orders, setOrders] = useState<PaginatedResult<TransactionHeader>>(null);
  const [filter, setFilter] = useState<OrderSearchFilter>({
    status: getDefaultStatusFilter(),
    type: [],
    createdFrom: null,
    createdFromTime: null,
    createdTo: null,
    createdToTime: null,
    customerCode: null,
    profitCenterCode: null,
    
  });
  const [loading, setLoading] = useState<boolean>(false);
  
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!props.customer) {
      return;
    }

    if (props.showOrders) {
      setLoading(true)
      
      const queryString = new URLSearchParams();
      queryString.set('pageSize', '20');
      queryString.set('pageIndex', '0');
      
      queryString.set('fCustomerId', props.customer.id);
      if (filter.status.length > 0) {
        filter.status.map((status) => {
          queryString.append('fStatus', status.value);
        });
      }
      //fetch(`api/pick-n-pay/customers/${props.customer.id}/orders?${queryString.toString()}`)
      fetch(`api/pick-n-pay/orders/search?${queryString.toString()}`)
        .then(r => r.json())
        .then(data => {
          setOrders(data);
          setLoading(false);
        });
    }
  }, [props.customer, filter]);
  
  const deleteCustomer = (id:string)=>{
    fetch(`api/pick-n-pay/customers/${id}`, { method: 'DELETE' })
      .then(r=>{
        navigate('/pick-n-pay/customers');
      });
  }

  return (
    <>
      <section className={"customer-details card-section"}>
        <h2>
          {props.linkToCustomer && (
          <Link to={`/pick-n-pay/customers/${encodeURIComponent(props.customer.id)}`}>
            {props.customer.lastName}, {props.customer.name}
          </Link>
          )}

          {!props.linkToCustomer && (
            <span>{props.customer.lastName}, {props.customer.name}</span>
          )}
        </h2>
        <div className="card-group">
          <LabelValue label={t('pick-n-pay.customers.details.id')} value={props.customer.id} />
          <LabelValue label={t('pick-n-pay.customers.details.code')} value={props.customer.customerCode} />
          <LabelValue label={t('pick-n-pay.customers.details.email')} value={props.customer.email} />
          {props.showAccountStatus && (
          <>
          <LabelValue label={t('pick-n-pay.customers.details.emailConfirmed')} value={props.customer.emailConfirmed.toString()} />
          <LabelValue label={t('pick-n-pay.customers.details.accessFailed')} value={props.customer.accessFailedCount} />
          <LabelValue label={t('pick-n-pay.customers.details.lockedOut')} value={(props.customer.lockoutEnd !== null).toString()} />
          </>
          )}
        
        {showActions && (
          <div className={"btn-group"}>
            <ButtonConfirm title={t('pick-n-pay.customers.details.delete.text')} className={"btn btn-delete"} message={t('pick-n-pay.customers.details.delete.confirmText')} onConfirm={()=>deleteCustomer(props.customer.id)}>
              {t('pick-n-pay.customers.details.delete.text')}
            </ButtonConfirm>
            {/*<ButtonConfirm title={"Reset Password"} className={"btn"} message={t('pick-n-pay.customers.details.resetPassword.confirmText')} onConfirm={()=>resetPassword(props.customer.id)}>*/}
            {/*  {t('pick-n-pay.customers.details.resetPassword.text')}*/}
            {/*</ButtonConfirm>*/}
          </div>
        )}
        </div>
      </section>
      {loading && <p>Loading...</p>}
      {!loading && showOrders && (
        <div className={"customer-orders"}>
          <h3>{t('pick-n-pay.customers.details.customer-orders.header')}</h3>

          <div className={"filter-container"}>
            <div className={"input-group"}>
              <div className={"input-group"}>
                <label>{t(`pick-n-pay.orders.status.label`)}</label>
                <Select name={"fStatus"} onChange={(e: any[]) => {
                  setFilter({...filter, status: e });
                }} defaultValue={filter.status} options={statuses} isMulti={true}/>
                <p className={"form-info"}>{t(`pick-n-pay.orders.status.description`)}</p>
              </div>
            </div>
          </div>

          <div className={"search-result-container"}>
            <div className="search-result-header">
              <span>{t('pick-n-pay.orders.list-headers.created')}</span>
              <span>{t('pick-n-pay.orders.list-headers.orderNr')}</span>
              <span>{t('pick-n-pay.orders.list-headers.status')}</span>
              <span>{t('pick-n-pay.orders.list-headers.type')}</span>
              <span>{t('pick-n-pay.orders.list-headers.shop')}</span>
              <span>{t('pick-n-pay.orders.list-headers.customer')}</span>
              <span>{t('pick-n-pay.orders.list-headers.total')}</span>
              <span></span>
            </div>
          
            <ol className={"search-result"}>
              {orders?.items?.map((order: TransactionHeader) => (
                <OrderRow order={order} key={order.guid} showCustomer={false}/>
              ))}
              {orders?.items?.length === 0 && (
                <li><p>{t('pick-n-pay.orders.list.empty')}</p></li>
              )}
            </ol>
          </div>
        </div>
      )}

      {props.onClose && (
        <div className={"btn-group"}>
          <button onClick={() => props.onClose!()} className={"btn"}>Close</button>
        </div>
      )}

    
    </>
  );
}

CustomerDetails.defaultProps = {
  showOrders: true,
  showActions: true,
  showAccountStatus: true,
  linkToCustomer: true
}

export default CustomerDetails;